import Vue from 'vue';
//import each of components used in shared repo individually to save space for build
import Vuetify, { VAppBar } from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {
  components: {
    VAppBar
  }
});

const opts = {
  theme: {
    primary: '#68caa6',
    radioactive: '#0ACF23'
  },
  icons: {
    iconfont: 'md'
  }
};

export default new Vuetify(opts);
