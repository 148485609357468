import localForage from 'localforage';
import { Auth } from 'aws-amplify';

/**
 * Function to clear the data in the indexedDB
 *
 * @returns successfulCallback
 */
async function clearAll() {
  return await localForage.clear();
} // clearAll

/**
 * Clears all the credentials in the indexedDB
 */
async function clearCredentials() {
  await localForage.removeItem('credentials');
} // clearStorage

/**
 * Clear signed out variable in local forage
 */
async function clearSignedOut() {
  await localForage.removeItem('signedOut');
} //clearSignedOut

/**
 * Returns login credentials that are stored in indexedDB
 *
 * @returns login credentials from indexedDB storage
 */
async function getCredentials() {
  let data = JSON.parse(await localForage.getItem('credentials'));
  return data;
} // getCredentials

/**
 * gets the signedOut variable in the indexedDB storage
 *
 * @returns signedOut
 */
async function getSignedOut() {
  return await localForage.getItem('signedOut');
} // getSignedOut

/**
 * retrieves the current session from Auth(Cognito user pool), builds an object from the tokens and user data
 * and then sets it to the indexedDB
 */
async function setCredentials() {
  try {
    let data = await Auth.currentSession();
    let splitISS = data.accessToken.payload.iss.split('/');
    let userPoolID = splitISS[splitISS.length - 1];
    let storagePayload = {
      tokens: {
        idJWT: data.idToken.jwtToken,
        accessJWT: data.accessToken.jwtToken,
        refreshJWT: data.refreshToken.token
      },
      times: {
        authTime: data.idToken.payload.auth_time,
        expTime: data.idToken.payload.exp,
        iatTime: data.idToken.payload.iat
      },
      userInfo: {
        userName: data.idToken.payload['cognito:username'],
        userGroup: data.idToken.payload['cognito:groups'] //an array
      },
      userPool: {
        userPoolId: userPoolID,
        userPoolClientID: data.accessToken.payload.client_id
      }
    };
    let userPoolData = {
      userPoolID: userPoolID,
      userPoolClientID: data.accessToken.payload.client_id
    };

    localStorage.setItem('userPool', JSON.stringify(userPoolData));

    localForage.setItem('credentials', JSON.stringify(storagePayload));
  } catch (err) {
    //console.err(err);
  }
} // setCredentials

export default {
  clearAll,
  clearCredentials,
  clearSignedOut,
  getCredentials,
  getSignedOut,
  setCredentials
};
