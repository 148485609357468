import Vue from 'vue';
import Router from 'vue-router';

import LogIn from '@/views/LogIn';
import { Auth } from 'aws-amplify';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'LogIn',
      component: LogIn
    }
  ]
});

//makes it so people can't route to anything without being logged in
router.beforeEach(async (to, from, next) => {
  let isAuth = await isAuthenticated();
  if (to.name == 'LogIn') {
    next();
  } else if (!isAuth) {
    next({
      path: '/'
    });
  } else {
    next();
  }
});

/**
 * Checks if a user is authenticated with cognito
 *
 * @param user - user to check
 * @returns boolean - whether or not user is authenticated
 */
export async function isAuthenticated() {
  try {
    let data = await Auth.currentSession();
    if (data != null) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
} // isAuthenticated

export default router;
