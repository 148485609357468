<template>
  <div>
    <!-- Built in Amplify Authenticator app with vue components-->
    <amplify-authenticator>
      <!--Amplify Sign out view-->
      <amplify-sign-in slot="sign-in" :form-fields.prop="formFields" hide-sign-up></amplify-sign-in>
      <!-- The rest of your app code -->
      <div v-if="user">
        <h1>Hello, {{ user.username }}</h1>
      </div>
      <div v-if="redirecting">
        <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
        <div>Redirecting to App</div>
      </div>
      <!-- Header -->
      <v-app-bar class="app-bar-bc" fixed app clipped-left>
        <!-- Desktop View -->
        <v-toolbar-title>
          <h2 class="ghost-white-c">{{ appName }}</h2>
        </v-toolbar-title>
        <v-spacer />
        <!-- User image and logout (only displayed if not in local env)-->
        <v-btn color="#c0c7d8" v-on:click="signOut"><b>Logout</b></v-btn>
      </v-app-bar>
      <!-- End Header -->
      <v-row class="justify-center px-6">
        <!-- Delphi -->
        <v-spacer></v-spacer>
        <v-col v-if="userGroup.includes('delphi')" cols="12" md="4" @click="redirect('delphi')">
          <v-card elevation="5" class="ml-0 pa-6 form-card" color="#c0c7d8">
            <v-card-title class="justify-center"> DELPHI </v-card-title>
          </v-card>
        </v-col>
        <!-- End Delphi -->
        <v-spacer sm="12" v-if="userGroup.includes('delphi') && userGroup.includes('pythia')"></v-spacer>
        <!-- Pythia -->
        <v-col v-if="userGroup.includes('pythia')" cols="12" md="4" @click="redirect('pythia')">
          <v-card elevation="5" class="pa-6 form-card" color="#c0c7d8">
            <v-card-title class="justify-center"> PYTHIA </v-card-title>
          </v-card>
        </v-col>
        <!-- End Pythia -->
        <v-spacer></v-spacer>
      </v-row>
    </amplify-authenticator>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import Storage from '@/utils/storageUtils';
import moment from 'moment';

// |--------------------------------------------------|
// |                                                  |
// |                 LIFECYCLE HOOKS                  |
// |                                                  |
// |--------------------------------------------------|

/**
 * Handles when user is signed in and signed out
 */
async function created() {
  //checks to see if a user has signed out from any of the sub apps (delphi/pythia)
  //if they have makes sure to sign user out of the amplify app
  if (await Storage.getSignedOut()) {
    await Auth.signOut();
    await Storage.clearCredentials();
    await Storage.clearSignedOut();
    await localStorage.removeItem('userPool');
  }

  let credentials = await Storage.getCredentials();
  //already has credentials
  if (credentials) {
    //check to see if credentials have expired
    let expiration = credentials.times.expTime;
    let exp = moment.unix(expiration);
    let now = moment();
    if (now.isAfter(exp)) {
      await Auth.signOut();
      await Storage.clearCredentials();
    }
  }
  //defines what to do when amplify state has changed
  onAuthUIStateChange(async (authState, authData) => {
    //sets user if user signs in for first time
    this.user = authData;

    //if the user belongs to a user group set the userGroup variable
    if (
      this.user &&
      this.user.signInUserSession &&
      this.user.signInUserSession.accessToken &&
      this.user.signInUserSession.accessToken.payload
    ) {
      this.userGroup = this.user.signInUserSession.accessToken.payload['cognito:groups'];
    }
    //when user signs in to the amplify app
    if (authState == 'signedin') {
      //sets credentials in browser
      await Storage.setCredentials();
    } else {
      //if user is not signed in, clears the user credentials from the browser
      await Storage.clearCredentials();
    }
    //sets auth state
    this.authState = authState;
  });
  //sets user if user is already logged in
  if (this.user === undefined) {
    //set's user
    this.user = Auth.user;

    //if the user belongs to a user group set the userGroup variable
    if (
      this.user &&
      this.user.signInUserSession &&
      this.user.signInUserSession.accessToken &&
      this.user.signInUserSession.accessToken.payload
    ) {
      this.userGroup = this.user.signInUserSession.accessToken.payload['cognito:groups'];
    }
  }
} // created

// |--------------------------------------------------|
// |                                                  |
// |                    COMPUTED                      |
// |                                                  |
// |--------------------------------------------------|

/**
 * App name to show up in navigation bar based on env.
 * @returns String - Generic app name with env appended to it. Ex. Portal - LOCAL
 */
function appName() {
  return 'Portal' + ' - ' + process.env.VUE_APP_STAGE.toUpperCase();
} //appName

// |--------------------------------------------------|
// |                                                  |
// |                    METHODS                       |
// |                                                  |
// |--------------------------------------------------|

/**
 * Signs a user out of the app
 */
async function signOut() {
  try {
    await Auth.signOut();
    localStorage.removeItem('userPool');
  } catch (error) {
    //console.log('error signing out: ', error);
  }
} //sign out

/**
 * Redirects the user to the designated app
 */
function redirect(appName) {
  //handles redirects deployed env
  if (process.env.VUE_APP_STAGE != 'local') {
    if (appName == 'pythia' && this.userGroup && this.userGroup.includes('pythia')) {
      this.redirecting = true;
      window.location.replace(process.env.VUE_APP_PYTHIA_URL);
    } else if (appName == 'delphi' && this.userGroup && this.userGroup.includes('delphi')) {
      this.redirecting = true;
      window.location.replace(process.env.VUE_APP_DELPHI_URL);
    }
  } else {
    //handles local env redirects
    if (appName == 'pythia' && this.userGroup && this.userGroup.includes('pythia')) {
      this.redirecting = true;
      window.open(process.env.VUE_APP_PYTHIA_URL, '_blank'); //opens app in new tab
    } else if (appName == 'delphi' && this.userGroup && this.userGroup.includes('delphi')) {
      this.redirecting = true;
      window.open(process.env.VUE_APP_DELPHI_URL, '_blank'); //opens app in new tab
    }
  }
  this.redirecting = false; //sets redirecting boolean to false
} //redirect

// |--------------------------------------------------|
// |                                                  |
// |                      EXPORT                      |
// |                                                  |
// |--------------------------------------------------|

export default {
  created,
  beforeDestroy() {
    return onAuthUIStateChange;
  },
  computed: {
    appName
  },
  data() {
    return {
      user: undefined, //sets user
      userGroup: [], //sets the user group the user belongs to (array)
      authState: undefined, //the auth state registered by amplify app
      formFields: [
        {
          type: 'username',
          label: 'Username',
          placeholder: 'Username',
          required: true
        },
        {
          type: 'password',
          label: 'Password',
          placeholder: 'Password',
          required: true,
          hint: null
        }
      ], //custom form fields used by amplify app sign in
      redirecting: false
    };
  },
  methods: {
    redirect,
    signOut
  },
  watch: {
    //checking if user is signed in and which group they are in if they are
    authState: async function () {
      //redirects if signed in for first time
      if (this.authState === 'signedin' && this.user) {
        //handle deployed envs
        if (process.env.VUE_APP_STAGE != 'local') {
          let credentials = await Storage.getCredentials();
          if (this.userGroup && this.userGroup.includes('pythia')) {
            if (credentials) {
              this.redirecting = true;
              window.location.replace(process.env.VUE_APP_PYTHIA_URL);
            }
          } else if (this.userGroup && this.userGroup.includes('delphi')) {
            if (credentials) {
              this.redirecting = true;
              window.location.replace(process.env.VUE_APP_DELPHI_URL);
            }
          }
        } else {
          //handle local env
          if (this.userGroup && this.userGroup.includes('pythia')) {
            this.redirecting = true;
            window.open(process.env.VUE_APP_PYTHIA_URL, '_blank');
          } else if (this.userGroup && this.userGroup.includes('delphi')) {
            this.redirecting = true;
            window.open(process.env.VUE_APP_DELPHI_URL, '_blank');
          }
        }
      }
      this.redirecting = false;
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.form-card {
  border: 2px !important;
  border-style: solid !important;
  border-color: black !important;
}

.form-card:hover {
  cursor: pointer;
  background-color: #abb2c2 !important;
}
</style>
